/* App.css */
.App {
  background: linear-gradient(135deg, #271B4D 0%, #322659 50%, #1A1333 100%);
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: flex-start; /* Change this */
  justify-content: center;
  animation: gradientShift 15s ease infinite;
  background-size: 200% 200%;
  padding-top: 40px; /* Add some padding at the top */
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(159, 122, 234, 0.05) 0%, rgba(26, 19, 51, 0) 70%);
  pointer-events: none;
}

.page-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: white;
}

.back-button {
  top: 20px;
  left: 20px;
  padding: 8px 16px;
  background: rgba(107, 70, 193, 0.2);
  border: 1px solid #6B46C1;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(107, 70, 193, 0.4);
}