.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(26, 19, 51, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
  }
  
  .modal-content {
    background: rgba(107, 70, 193, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(159, 122, 234, 0.2);
    border-radius: 12px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    animation: slideIn 0.3s ease-out;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    color: #FFFFFF;
    margin: 0;
    font-family: 'Urbanist', sans-serif;
    font-size: 1.5rem;
  }
  
  .modal-close {
    background: none;
    border: none;
    color: #9F7AEA;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    line-height: 1;
    transition: color 0.3s ease;
  }
  
  .modal-close:hover {
    color: #6B46C1;
  }
  
  .modal-body {
    margin-bottom: 20px;
  }
  
  .modal-body p {
    color: #E2E8F0;
    margin: 0;
    font-family: 'Urbanist', sans-serif;
    line-height: 1.5;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .modal-button {
    background: linear-gradient(135deg, #6B46C1 0%, #805AD5 100%);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    font-family: 'Urbanist', sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #805AD5 0%, #9F7AEA 100%);
  }
  
  .modal-button.submit-btn {
    background: linear-gradient(135deg, #6B46C1 0%, #805AD5 100%);
  }
  
  .modal-button.cancel-btn {
    background: rgba(107, 70, 193, 0.2);
    border: 1px solid #6B46C1;
  }
  
  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .modal-body ol {
    padding-left: 20px;
    color: #E2E8F0;
    margin: 15px 0;
  }
  
  .modal-body ul {
    padding-left: 20px;
    list-style-type: disc;
  }

  .modal-text, .modal-list {
    color: #E2E8F0;
  }
  
  .modal-list {
    padding-left: 20px;
    margin: 15px 0;
  }
  
  .modal-list li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      padding: 15px;
    }
  
    .modal-header h2 {
      font-size: 1.3rem;
    }
  
    .modal-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }