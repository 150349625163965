/* Home.css */
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin-top: 0;
}

.header-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.side-logo {
  width: 150px;
  height: auto;
  filter: brightness(1.3);
}

.text-content {
  text-align: center;
  min-width: 300px;
}

.company-name {
  font-family: 'SonaraBC', serif;
  color: #9F7AEA;
  margin: 0;
  font-size: 3rem;
  text-shadow: 0 0 20px rgba(159, 122, 234, 0.5);
}

.tagline {
  font-family: 'Urbanist', sans-serif;
  color: #9F7AEA;
  text-transform: lowercase;
  margin: 5px 0 0 0;
  font-size: 1.5rem;
  text-shadow: 0 0 15px rgba(159, 122, 234, 0.4);
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 600px;
}

.nav-button {
  width: 100%;
  background: linear-gradient(135deg, #6B46C1 0%, #805AD5 100%);
  color: white;
  padding: 16px 24px;
  border: none;
  border-radius: 8px;
  font-family: 'Urbanist', sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: all 0.3s ease;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, #805AD5 0%, #9F7AEA 100%);
}

.nav-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .header-section {
    flex-direction: column;
    gap: 20px;
  }

  .side-logo {
    width: 120px;
  }
  
  .text-content {
    min-width: unset;
    width: 100%;
  }

  .company-name {
    font-size: 2.5rem;
  }
  
  .tagline {
    font-size: 1.25rem;
  }
  
  .button-container {
    gap: 12px;
  }

  .nav-button {
    padding: 14px 20px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .header-section {
    gap: 15px;
  }

  .side-logo {
    width: 100px;
  }

  .company-name {
    font-size: 2rem;
  }
  
  .tagline {
    font-size: 1rem;
  }

  .nav-button {
    padding: 12px 16px;
  }
}