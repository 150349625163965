/* Submit.css */
.submit-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
  }
  
  .submit-content {
    background: rgba(107, 70, 193, 0.1);
    border-radius: 12px;
    padding: 30px;
    backdrop-filter: blur(10px);
    border: 1px solid rgba(159, 122, 234, 0.2);
  }
  
  .submit-content h1 {
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 30px;
    font-family: 'Urbanist', sans-serif;
  }
  
  .limits-info {
    margin-bottom: 20px;
    padding: 15px;
    background: rgba(107, 70, 193, 0.2);
    border-radius: 8px;
    color: #E2E8F0;
  }
  
  .limits-info p {
    margin: 5px 0;
    font-size: 0.9rem;
  }
  
  .submit-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .report-input {
    width: 100%;
    min-height: 300px;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgba(159, 122, 234, 0.3);
    background: rgba(26, 19, 51, 0.6);
    color: #FFFFFF;
    font-family: 'Urbanist', sans-serif;
    font-size: 1rem;
    resize: vertical;
    transition: all 0.3s ease;
  }
  
  .report-input:focus {
    outline: none;
    border-color: #6B46C1;
    box-shadow: 0 0 0 2px rgba(107, 70, 193, 0.2);
  }
  
  .char-counter {
    text-align: right;
    color: #A0AEC0;
    font-size: 0.9rem;
  }
  
  .error-message {
    color: #E53E3E;
    padding: 10px;
    background: rgba(229, 62, 62, 0.1);
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .submit-button {
    background: linear-gradient(135deg, #6B46C1 0%, #805AD5 100%);
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    font-family: 'Urbanist', sans-serif;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .submit-button:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #805AD5 0%, #9F7AEA 100%);
  }
  
  .submit-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  @media (max-width: 768px) {
    .submit-container {
      padding: 20px 15px;
    }
  
    .submit-content {
      padding: 20px;
    }
  
    .report-input {
      min-height: 200px;
    }
  }

  .button-stack {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }
  
  .back-button {
    padding: 15px 30px;
    background: rgba(107, 70, 193, 0.2);
    border: 1px solid #6B46C1;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    font-size: 1.1rem;
  }
  
  .back-button:hover {
    background: rgba(107, 70, 193, 0.4);
  }